
<template>
	<form class="column" id="contact-us" method="post" :action="actionurl" @submit.prevent="submitForm">
		<input type="hidden" name="_token" :value="csrf">
		<div class="columns is-12-tablet is-multiline">
			<h2 class="column is-12-tablet">Contact Us</h2>
				<div class="column is-6-tablet">
					<div class="field" :class="validators.subject.control.groupClass">
						<div class="control has-icons-right">
							<div :class="validators.subject.control.controlClass">
								<input maxlength="100" type="textarea" name="subject" class="form-control input" id="subject" placeholder="Subject" v-model="contactUs.subject">
								<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.subject.control.iconClass"></i></span>
							</div>
						</div>
						<small class="help-block">{{ validators.subject.control.errorMessage }}</small>
					</div>
					<div class="field" :class="validators.message.control.groupClass">
						<div class="control has-icons-right">
							<div :class="validators.message.control.controlClass">
								<textarea maxlength="499" class="textarea form-control" rows="7" name="message" id="exampleTextarea" placeholder="Message" v-model="contactUs.message"></textarea>
								<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.message.control.iconClass"></i></span>
							</div>
						</div>
						<small class="help-block">{{ validators.message.control.errorMessage }}</small>
					</div>
				</div>
				<div class="column is-6-tablet full-form">
					<div class="field" :class="validators.email.control.groupClass">
						<div class="control has-icons-right">
							<div :class="validators.email.control.controlClass">
								<input maxlength="60" class="form-control input" name="email" id="email" placeholder="Your Email Address" v-model="contactUs.email" @keyup="validateEmail">
								<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.email.control.iconClass"></i></span>
							</div>
						</div>
						<small class="help-block">{{ validators.email.control.errorMessage }}</small>
					</div>
					<div class="field" :class="validators.name.control.groupClass">
						<div class="control has-icons-right">
							<div :class="validators.name.control.controlClass">
								<input maxlength="100" type="text" class="form-control input" name="name" id="name" placeholder="Name" v-model="contactUs.name" >
								<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.name.control.iconClass"></i></span>
							</div>
						</div>
						<small class="help-block">{{ validators.name.control.errorMessage }}</small>
					</div>
					<div class="field" :class="validators.contact.control.groupClass">
						<div class="control has-icons-right">
							<div :class="validators.contact.control.controlClass">
								<input maxlength="11" type="text" class="form-control input"  name="contact-number" id="contact-number" placeholder="Contact Number" v-model="contactUs.contact" >
								<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.contact.control.iconClass"></i></span>
							</div>
						</div>
						<small class="help-block">{{ validators.contact.control.errorMessage }}</small>
					</div>
					<div class="form-group">
						<!-- <input type="submit"  class="col-sm-6 btn btn-p0 btn-lg pull-right" value="SUBMIT"> -->
						<button class="btn btn-p0 btn-lg column is-6-tablet is-pulled-right" :attribute="{ 'disabled=disabled': disabledBtn }" @submit="submitForm($event)">SUBMIT</button>
					</div>
				</div>
			</div>
		</form>
</template>

<script>
	export default {
		props: ['actionurl', 'csrf'],
		data() {
			return {
				contactUs: {
					subject: '',
					message: '',
					email: '',
					name: '',
					contact: ''
				},
				disabledBtn: '',
				validators: {
					email: {
						required: 'This email field cannot be empty',
						stringLength : {
							max: 60,
							message: 'This field cannot exceed 60 character limit'
						},
						regex: {
							rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							message: 'Email only accepts alphanumeric characters, follow with @ - . for email'
						},
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default',
							iconClass: ''
						},
						isValid: false
					},
					message: {
						required: 'This message field cannot be empty',
						stringLength: {
							max: 500,
							message: 'This field cannot exceed 500 character limit'
						},
						stringLengthMin: {
							min: 1,
							message: 'This field cannot be empty'
						},
						regex : {
							rule: /^[a-zA-Z0-9 %`@()'.?():,\n+/-]+$/,
							message: 'This message field only accepts (a-z), (A-Z) (0-9) and following characters % ` @ ( )  / . ? ( ) : , + / -'
						},
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default',
							iconClass: ''
						},
						isValid: false
					},
					name: {
						required: 'This name field cannot be empty',
						stringLength : {
							max: 100,
							message: 'This field cannot exceed 100 character limit'
						},
						stringLengthMin: {
							min: 1,
							message: 'This name field cannot be empty'
						},
						regex: {
							rule: /^[a-zA-Z `@()'/,.-]*$/,
							message: 'Name only accepts alphabets characters'
						},
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default',
							iconClass: ''
						},
						isValid: false
					},
					contact: {
						regex: {
							rule: /^\d{9,11}$/,
							message: 'Contact Number only accepts numbers(0-9) and must be between 9-11 digits'
						},
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default',
							iconClass: ''
						},
						isValid: false
					},
					subject: {
						required: 'This subject field cannot be empty',
						stringLength : {
							max: 100,
							message: 'This field cannot exceed 100 character limit'
						},
						stringLengthMin: {
							min: 1,
							message: 'This field cannot be empty'
						},
						regex: {
							rule: /^[a-z0-9 ]+$/i, 
							message: 'Subject only accepts alphanumberic characters'
						},
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default',
							iconClass: ''
						},
						isValid: false
					}
				}
			}
		},

		watch: {
			'contactUs.email': function(v) {
					{
						this.validators.email.control = {
							groupClass: 'has-success',
							controlClass: 'form-control-success',
							iconClass: 'fa-check',
							errorMessage: ''
						}
						this.validators.email.isValid = true
						this.disabledBtn = false;
					}
					// required
					if(v === '') {
						this.validators.email.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.email.required
						}
						this.validators.email.isValid = false;
						this.disabledBtn = true;
					} 
				// stringLength
				if(v.length > this.validators.email.stringLength.max || v.length === 0) {
					this.validators.email.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.email.stringLength.email
					}
					this.validators.email.isValid = false;
					this.disabledBtn = true;
				} 
				// Regex
				let regex = RegExp(this.validators.email.regex.rule)
				if (!regex.test(v)) {
					this.validators.email.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.email.regex.message
					}

					this.validators.email.isValid = false;
					this.disabledBtn = true;
				} else {
					this.validators.email.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}
					this.validators.email.isValid = true
					this.disabledBtn = false;
				}
			},
			'contactUs.name': function(v) {
				
				{
					this.validators.name.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}

					this.validators.name.isValid = true;
					this.disabledBtn = false;
				}

					// required
					if(this.contactUs.name === '') {
						this.validators.name.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.name.required
						}
						this.validators.name.isValid = false;
						this.disabledBtn = true;

					} 
				// stringLength
				if(this.contactUs.name.length > this.validators.name.stringLength.max || this.contactUs.name.length === 0) {
					this.validators.name.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.name.stringLength.message
					}
					this.validators.name.isValid = false;
					this.disabledBtn = true;

				} 
				// Regex
				if (!this.validators.name.regex.rule.test(this.contactUs.name)) {
					this.validators.name.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.name.regex.message
					}

					this.validators.name.isValid = false;
					this.disabledBtn = true;
				}

					// stringLengthMin
					if(this.contactUs.name.length < this.validators.name.stringLengthMin.min || this.contactUs.name.trim().length === 0) {
						this.validators.name.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.name.stringLengthMin.message
						}
						this.validators.name.isValid = false;
						this.disabledBtn = true;
					}

				},
				'contactUs.contact': function(v) {
				// Regex
				if (this.validators.contact.regex.rule.test(this.contactUs.contact)) {
					this.validators.contact.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}

					this.validators.contact.isValid = true;
					this.disabledBtn = false;
				} else {
					this.validators.contact.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.contact.regex.message
					}

					this.validators.contact.isValid = false;
					this.disabledBtn = true;
				}

			},
			'contactUs.subject': function(v) {
				
				{
					this.validators.subject.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}

					this.validators.subject.isValid = true;
					this.disabledBtn = false;
				}

					// required
					if(this.contactUs.subject === '') {
						this.validators.subject.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.subject.required
						}
						this.validators.subject.isValid = false;
						this.disabledBtn = true;

					} 
				// stringLength
				if(this.contactUs.subject.length > this.validators.subject.stringLength.max || this.contactUs.subject.length === 0) {
					this.validators.subject.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.subject.stringLength.message
					}
					this.validators.subject.isValid = false;
					this.disabledBtn = true;

				} 
				// Regex
				if (!this.validators.subject.regex.rule.test(this.contactUs.subject)) {
					this.validators.subject.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.subject.regex.message
					}

					this.validators.subject.isValid = false;
					this.disabledBtn = true;
				}

					// stringLengthMin
					if(this.contactUs.subject.length < this.validators.subject.stringLengthMin.min || this.contactUs.subject.trim().length === 0) {
						this.validators.subject.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.subject.stringLengthMin.message
						}
						this.validators.subject.isValid = false;
						this.disabledBtn = true;
					}

				},
				'contactUs.message': function(v) {
					this.validators.message.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}
					this.validators.message.isValid = true;
					this.disabledBtn = false;

					// required
					if(this.contactUs.message.trim() === '') {
						this.validators.message.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.message.required
						}
						this.validators.message.isValid = false;
						this.disabledBtn = true;
					}

					// regex
					if (!this.validators.message.regex.rule.test(this.contactUs.message)) {
					this.validators.message.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.message.regex.message
					}

					this.validators.message.isValid = false;
					this.disabledBtn = true;
					}


					// stringLength
					if(this.contactUs.message.length > this.validators.message.stringLength.max || this.contactUs.message.trim().length === 0) {
						this.validators.message.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.message.stringLength.message
						}
						this.validators.message.isValid = false;
						this.disabledBtn = true;
					}

					// stringLength
					if(this.contactUs.message.length < this.validators.message.stringLengthMin.min || this.contactUs.message.trim().length === 0) {
						this.validators.message.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.message.stringLengthMin.message
						}
						this.validators.message.isValid = false;
						this.disabledBtn = true;
					}
				},
			},
			methods: {
				validateEmail() {
					{
						this.validators.email.control = {
							groupClass: 'has-success',
							controlClass: 'form-control-success',
							errorMessage: ''
						}
						this.validators.email.isValid = true;
						this.disabledBtn = false;
					}
					if(this.contactUs.email === '') {
						this.validators.email.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							errorMessage: this.validators.email.required
						}
						this.validators.email.isValid = false;
						this.disabledBtn = true;
					} 
					// stringLength
					if(this.contactUs.email.length > this.validators.email.stringLength.max || this.contactUs.email.length === 0) {
						this.validators.email.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.email.stringLength.email
						}
						this.validators.email.isValid = false;
						this.disabledBtn = true;
					} 
				// Regex
				let regex = RegExp(this.validators.email.regex.rule)
				if (!regex.test(this.contactUs.email)) {
					this.validators.email.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.email.regex.message
					}

					this.validators.email.isValid = false;
					this.disabledBtn = true;
				} else {
					this.validators.email.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}
					this.validators.email.isValid = true
					this.disabledBtn = false;
				}

			},
			submitForm(e) {
				if(this.contactUs.subject !== '' && this.validators.subject.isValid &&
					this.contactUs.message !== '' && this.validators.message.isValid &&
					this.contactUs.email !== '' && this.validators.email.isValid &&
					this.contactUs.name !== '' && this.validators.name.isValid &&
					this.contactUs.contact !== '' && this.validators.contact.isValid) {
					console.log('passed')
				this.disabledBtn = false

				document.getElementById('contact-us').submit();

					// Google Analytic submit tracking code

                    var dataObject = {
                        'event': 'trackEvent',
                        'eventCategory': 'Contact Us',
                        'eventAction': 'Submit',
                        'eventLabel': 'Submit Contact Us'
                    };

                    if(typeof dataLayer != 'undefined'){
                        console.log(dataLayer);
                        dataLayer.push(dataObject);
                    }
					// return true
				} else {

					if (this.contactUs.subject == '') {
						this.validators.subject.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.subject.required
						}
					}

					if (this.contactUs.message == '') {
						this.validators.message.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.message.required
						}
					}


					if (this.contactUs.email == '') {
						this.validators.email.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.email.required
						}
					}

					if (this.contactUs.name  == '') {
						this.validators.name.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.name.required
						}
					}

					if (!this.validators.contact.regex.rule.test(this.contactUs.contact)) {
						this.validators.contact.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.contact.regex.message
						}
					}
					e.preventDefault();
					console.log('not valid')
						// return false;
						this.disabledBtn = true
					}
				}
			}

		}

	</script>