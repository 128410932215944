import VueGtm from 'vue-gtm'
import formQuote from './layouts/form-qoute'
import formDemo from './layouts/form-demo'
import formContactUs from './layouts/form-contactus'
import gtmGenerator from './layouts/gtm-generator'

/**
 * JS dependencies here:
 */
require('./bootstrap');

Vue.use(VueGtm, {
    appName: 'Pasti test', // Mandatory
    appVersion: '1', // Mandatory
    trackingId: 'UA-99036874-1', // Mandatory
    debug: true, // Whether or not display console logs debugs (optional)
});

const app = new Vue({
    el: '#app',
    components: {
        formQuote,
        formDemo,
        formContactUs,
        gtmGenerator
    },
    data() {
        return {
            isActive: false,
            show: true
        }
    },
    methods: {
        toggleMenu: function() {
            if (this.isActive) {
                this.isActive = false;
                return;
            }

            this.isActive = true;
        },
    }
});
