<template>
	<div class=""></div>
</template>
<script> 
	export default {
		props: {
			eventLabel: {
				type: String
			},
			eventCategory: {
				type: String
			},
			eventAction: {
				type: String
			}
		},
		mounted () {	
			this.$gtm.trackEvent({
				'eventCategory': this.eventCategory,
				'eventAction': this.eventAction,
				'eventLabel': this.eventLabel
			});
		}
	}

</script>
