
<template>
	<form class="column" id="getaquote" method="post" :action="actionurl" @submit.prevent="submitForm">
		<input type="hidden" name="_token" :value="csrf">
		<div class="columns is-12-tablet is-multiline">	
			<h2 class="column is-12-tablet">Get a quote</h2>
			<div class="column is-6-tablet">
				<div class="field" :class="validators.package.control.groupClass">
					<div :class="validators.package.control.controlClass">
						<select class="form-control" name="package" id="package" v-model="quote.package" @change="packageChange">
							<option value="" selected disabled>Package Type</option>
							<option value="personal">Personal Package</option>
							<option value="business">Business Package</option>
							<option value="advance">Advance Package</option>
						</select>
					</div>
					<small class="help-block">{{ validators.package.control.errorMessage }}</small>
				</div>
				<div class="field" :class="validators.vehicles.control.groupClass">
					<div :class="validators.vehicles.control.controlClass">
						<select class="form-control" id="vehicles"  name="vehicles" v-model="quote.vehicles" @change="vehiclesChange">
							<option value="" selected disabled>Number of vehicles</option>
							<option value="1-5">1-5</option>
							<option value="6-10">6-10</option>
							<option value="11-20">11-20</option>
							<option value="Above 20">Above 20</option>
						</select>
					</div>
					<small class="help-block">{{ validators.vehicles.control.errorMessage }}</small>
				</div>
				<div class="field" :class="validators.message.control.groupClass">
					<div class="control has-icons-right">
						<div :class="validators.message.control.controlClass">
							<textarea maxlength="499" class="textarea form-control" rows="7" name="message" id="exampleTextarea" placeholder="Message" v-model="quote.message"  ></textarea>
							<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.message.control.iconClass"  ></i></span>
						</div>
					</div>
					<small class="help-block">{{ validators.message.control.errorMessage }}</small>
				</div>
			</div>
			<div class="column is-6-tablet">
				<div class="field" :class="validators.email.control.groupClass">
					<div class="control has-icons-right">
						<div :class="validators.email.control.controlClass">
							<input maxlength="60" class="input form-control" name="email" id="email" placeholder="Your Email Address" v-model="quote.email" @keyup="validateEmail">
							<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.email.control.iconClass"></i></span>
						</div>
					</div>
					<small class="help-block">{{ validators.email.control.errorMessage }}</small>
				</div>
				<div class="field" :class="validators.name.control.groupClass">
					<div class="control has-icons-right">
						<div :class="validators.name.control.controlClass">
							<input maxlength="100" type="text" class="input form-control" name="name" id="name" placeholder="Name" v-model="quote.name"  >
							<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.name.control.iconClass"></i></span>
						</div>
					</div>
					<small class="help-block">{{ validators.name.control.errorMessage }}</small>
				</div>
				<div class="field" :class="validators.contact.control.groupClass">
					<div class="control has-icons-right">
						<div :class="validators.contact.control.controlClass">
							<input maxlength="11" type="text" class="input form-control" name="contact-number" id="contact-number" placeholder="Contact Number" v-model="quote.contact" >
							<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.contact.control.iconClass"></i></span>
						</div>
					</div>
					<small class="help-block">{{ validators.contact.control.errorMessage }}</small>
				</div>
				<div class="field" :class="validators.company.control.groupClass">
					<div class="control has-icons-right">
						<div :class="validators.company.control.controlClass">
							<input maxlength="100" type="textarea" name="company" class="form-control input" id="company" placeholder="Company Name (Optional)" v-model="quote.company">
							<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.company.control.iconClass"></i></span>
						</div>
					</div>
					<small class="help-block">{{ validators.company.control.errorMessage }}</small>
				</div>
				<div class="control has-icons-left has-icons-right">
					<!-- <input type="submit" class="col-sm-6 btn btn-p0 btn-lg pull-right" value="SUBMIT">  -->
					
					<button class="btn btn-p0 btn-lg column is-6-tablet is-pulled-right" :onclass="{ 'disabled=disabled': disabledBtn }">SUBMIT</button>
				</div>
			</div>
		</div>
	</form>

</template>

<script>
	export default {
		props: ['actionurl', 'csrf'],
		data() {
			return {
				quote: {
					package: '',
					vehicles: '',
					message: '',
					email: '',
					name: '',
					contact: '',
					company:''
				},
				disabledBtn: '',
				validators: {
					package: {
						required: 'Please select this package field',
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default'
						},
						isValid: false
					},
					vehicles: {
						required: 'Please select this vehicles field',
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default'
						},
						isValid: false
					},
					message: {
						required: 'This message field cannot be empty',
						stringLength: {
							max: 500,
							message: 'This field cannot exceed 500 character limit'
						},
						stringLengthMin: {
							min: 1,
							message: 'This field cannot be empty'
						},
						regex : {
							rule: /^[a-zA-Z0-9 %`@()'.?():,\n+/-]+$/,
							message: 'This message field only accepts (a-z), (A-Z) (0-9) and following characters % ` @ ( )  / . ? ( ) : , + / -'
						},
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default',
							iconClass: ''
						},
						isValid: false
					},
					email: {
						required: 'This email field cannot be empty',
						stringLength : {
							max: 60,
							message: 'This field cannot exceed 60 character limit'
						},
						regex: {
							rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							message: 'Email only accepts alphanumeric characters, follow with @ - . for email'
						},
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default',
							iconClass: ''
						},
						isValid: false
					},
					name: {
						required: 'This name field cannot be empty',
						stringLength : {
							max: 100,
							message: 'This field cannot exceed 100 character limit'
						},
						stringLengthMin: {
							min: 1,
							message: 'This name field cannot be empty'
						},
						regex: {
							rule: /^[a-zA-Z `@()'/,.-]*$/,
							message: 'Name only accepts alphabets characters'
						},
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default',
							iconClass: ''
						},
						isValid: false
					},
					contact: {
						regex: {
							rule: /^\d{9,11}$/,
							message: 'Contact Number only accepts numbers(0-9) and must be between 9-11 digits'
						},
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default',
							iconClass: ''
						},
						isValid: false
					},
                    company: {
                        stringLength : {
                            max: 100,
                            message: 'This field cannot exceed 100 character limit'
                        },
                        stringLengthMin: {
                            min: 2,
                            message: 'Company Name must be between 2-100 characters'
                        },
                        regex: {
                            rule: /^([-a-zA-Z0-9%!'*@,&.():_ ™©®`\/-])*$/i,
                            message: 'Company Name only accepts both uppercase(A-Z) and lowercase(a-z) letters, numbers(0-9) and the following symbols % \' ` ( ) - & . / @ * , ! © ® ™'
                        },
                        control: {
                            errorMessage: '',
                            groupClass: 'has-default',
                            controlClass: 'form-control-default',
                            iconClass: ''
                        },
                        isValid: true
                    }
				}
			}
		},
		mounted() {
			let urlParams = new URLSearchParams(window.location.search);

			if (urlParams.has('package')) {
				this.validators.package.control = {
					groupClass: 'has-success',
					controlClass: 'form-control-success',
					iconClass: 'fa-check',
					errorMessage: ''
				};
				console.log(urlParams.get('package'));
				this.quote.package = urlParams.get('package');
				this.validators.package.isValid = true;
			}
		},
		watch: {
			'quote.message': function(v) {
				this.validators.message.control = {
					groupClass: 'has-success',
					controlClass: 'form-control-success',
					iconClass: 'fa-check',
					errorMessage: ''
				}
				this.validators.message.isValid = true;
				this.disabledBtn = false;

					// required
					if(this.quote.message.trim() === '') {
						this.validators.message.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.message.required
						}
						this.validators.message.isValid = false;
						this.disabledBtn = true;
					}

					// regex
					if (!this.validators.message.regex.rule.test(this.quote.message)) {
					this.validators.message.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.message.regex.message
					}

					this.validators.message.isValid = false;
					this.disabledBtn = true;
					}


					// stringLength
					if(this.quote.message.length > this.validators.message.stringLength.max || this.quote.message.trim().length === 0) {
						this.validators.message.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.message.stringLength.message
						}
						this.validators.message.isValid = false;
						this.disabledBtn = true;
					}

					// stringLength
					if(this.quote.message.length < this.validators.message.stringLengthMin.min || this.quote.message.trim().length === 0) {
						this.validators.message.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.message.stringLengthMin.message
						}
						this.validators.message.isValid = false;
						this.disabledBtn = true;
					}
				},
				'quote.email': function(v) {
					{
						this.validators.email.control = {
							groupClass: 'has-success',
							controlClass: 'form-control-success',
							iconClass: 'fa-check',
							errorMessage: ''
						}
						this.validators.email.isValid = true
						this.disabledBtn = false;
					}
					// required
					if(v === '') {
						this.validators.email.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.email.required
						}
						this.validators.email.isValid = false;
						this.disabledBtn = true;
					} 
				// stringLength
				if(v.length > this.validators.email.stringLength.max || v.length === 0) {
					this.validators.email.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.email.stringLength.email
					}
					this.validators.email.isValid = false;
					this.disabledBtn = true;
				} 
				// Regex
				let regex = RegExp(this.validators.email.regex.rule)
				if (!regex.test(v)) {
					this.validators.email.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.email.regex.message
					}

					this.validators.email.isValid = false;
					this.disabledBtn = true;
				} else {
					this.validators.email.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}
					this.validators.email.isValid = true
					this.disabledBtn = false;
				}
			},
			'quote.name': function(v) {
				
				{
					this.validators.name.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}

					this.validators.name.isValid = true;
					this.disabledBtn = false;
				}

					// required
					if(this.quote.name === '') {
						this.validators.name.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.name.required
						}
						this.validators.name.isValid = false;
						this.disabledBtn = true;

					} 
				// stringLength
				if(this.quote.name.length > this.validators.name.stringLength.max || this.quote.name.length === 0) {
					this.validators.name.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.name.stringLength.message
					}
					this.validators.name.isValid = false;
					this.disabledBtn = true;

				} 
				// Regex
				if (!this.validators.name.regex.rule.test(this.quote.name)) {
					this.validators.name.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.name.regex.message
					}

					this.validators.name.isValid = false;
					this.disabledBtn = true;
				}

					// stringLengthMin
					if(this.quote.name.length < this.validators.name.stringLengthMin.min || this.quote.name.trim().length === 0) {
						this.validators.name.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.name.stringLengthMin.message
						}
						this.validators.name.isValid = false;
						this.disabledBtn = true;
					}

				},
				'quote.contact': function(v) {
				// Regex
				if (this.validators.contact.regex.rule.test(this.quote.contact)) {
					this.validators.contact.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}

					this.validators.contact.isValid = true;
					this.disabledBtn = false;
				} else {
					this.validators.contact.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.contact.regex.message
					}

					this.validators.contact.isValid = false;
					this.disabledBtn = true;
				}

			},
            'quote.company': function(v) {
                {
                    this.validators.company.control = {
                        groupClass: 'has-success',
                        controlClass: 'form-control-success',
                        iconClass: 'fa-check',
                        errorMessage: ''
                    }

                    this.validators.company.isValid = true;
                    this.disabledBtn = false;
                }


                // stringLengthMin
                if(this.quote.company.length < this.validators.company.stringLengthMin.min && this.quote.company.trim().length !== 0 ) {
                    this.validators.company.control = {
                        groupClass: 'has-danger',
                        controlClass: 'form-control-danger',
                        iconClass: 'fa-times',
                        errorMessage: this.validators.company.stringLengthMin.message
                    }
                    this.validators.company.isValid = false;
                    return;
                }

			    // Regex
                if (this.quote.company == '') {
                    this.validators.company.isValid = true;

                    this.validators.company.control = {
                        groupClass: '',
                        controlClass: '',
                        iconClass: '',
                    }
                } else if (this.quote.company != '' && this.validators.company.regex.rule.test(this.quote.company)) {
                    this.validators.company.control = {
                        groupClass: 'has-success',
                        controlClass: 'form-control-success',
                        iconClass: 'fa-check',
                        errorMessage: ''
                    }

                    this.validators.company.isValid = true;
                } else {
                    this.validators.company.control = {
                        groupClass: 'has-danger',
                        controlClass: 'form-control-danger',
                        iconClass: 'fa-times',
                        errorMessage: this.validators.company.regex.message
                    }

                    this.validators.company.isValid = false;
                }
            },
		},
		methods: {
			packageChange() {
					// required
					if(this.quote.package === '') {
						this.validators.package.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							errorMessage: this.validators.package.required
						}
						this.validators.package.isValid = false;
						this.disabledBtn = true;
					} else {
						this.validators.package.control = {
							groupClass: 'has-success',
							controlClass: 'form-control-success',
							errorMessage: ''
						}
						this.validators.package.isValid = true;
						this.disabledBtn = false;
					}
				},
				vehiclesChange() {
					// required
					if(this.quote.vehicles === '') {
						this.validators.vehicles.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							errorMessage: this.validators.vehicles.required
						}
						this.validators.vehicles.isValid = false;
						this.disabledBtn = true;
					} else {
						this.validators.vehicles.control = {
							groupClass: 'has-success',
							controlClass: 'form-control-success',
							errorMessage: ''
						}
						this.validators.vehicles.isValid = true;
						this.disabledBtn = false;
					}
				},
				validateEmail() {
					{
						this.validators.email.control = {
							groupClass: 'has-success',
							controlClass: 'form-control-success',
							errorMessage: ''
						}
						this.validators.email.isValid = true;
						this.disabledBtn = false;
					}
					if(this.quote.email === '') {
						this.validators.email.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							errorMessage: this.validators.email.required
						}
						this.validators.email.isValid = false;
						this.disabledBtn = true;
					} 
					// stringLength
					if(this.quote.email.length > this.validators.email.stringLength.max || this.quote.email.length === 0) {
						this.validators.email.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.email.stringLength.email
						}
						this.validators.email.isValid = false;
						this.disabledBtn = true;
					} 
				// Regex
				let regex = RegExp(this.validators.email.regex.rule)
				if (!regex.test(this.quote.email)) {
					this.validators.email.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.email.regex.message
					}

					this.validators.email.isValid = false;
					this.disabledBtn = true;
				} else {
					this.validators.email.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}
					this.validators.email.isValid = true
					this.disabledBtn = false;
				}

			},
			submitForm() {

				if(this.quote.package !== '' && this.validators.package.isValid &&
					this.quote.message !== '' && this.validators.message.isValid &&
					this.quote.name !== '' && this.validators.name.isValid &&
					this.quote.email !== '' && this.validators.email.isValid &&
					this.quote.contact !== '' && this.validators.contact.isValid &&
                    this.validators.company.isValid) {

					console.log('passed')
				this.disabledBtn = false;

				document.getElementById('getaquote').submit();

                    // Google Analytic submit tracking code

                    var dataObject = {
                        'event': 'trackEvent',
                        'eventCategory': 'Quotation',
                        'eventAction': 'Submit',
                        'eventLabel': 'Request a Quote'
                    };

                    if(typeof dataLayer != 'undefined'){
                        console.log(dataLayer);
                        dataLayer.push(dataObject);
                    }
					// return true
				} else {
					if (this.quote.package == '') {
						this.validators.package.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.package.required
						}
					}

					if (this.quote.vehicles == '') {
						this.validators.vehicles.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.vehicles.required
						}
					}

					if (this.quote.message == '') {
						this.validators.message.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.message.required
						}
					}

					if (this.quote.email == '') {
						this.validators.email.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.email.required
						}
					}

					if (this.quote.name == '') {
						this.validators.name.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.name.required
						}
					}

					if (!this.validators.contact.regex.rule.test(this.quote.contact)) {
						this.validators.contact.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.contact.regex.message
						}
					}

                    if (this.quote.company != '' && !this.validators.company.regex.rule.test(this.quote.company)) {
                        this.validators.company.control = {
                            groupClass: 'has-danger',
                            controlClass: 'form-control-danger',
                            iconClass: 'fa-times',
                            errorMessage: this.validators.company.regex.message
                        }
                    }

					// e.preventDefault();
					console.log('not valid')
					// return false;
					this.disabledBtn = true
					}
				}
			}
		}
	</script>