
<template>
	<form class="column is-clearfix" id="get-a-demo" method="post" :action="actionurl" @submit.prevent="submitForm">
		<input type="hidden" name="_token" :value="csrf">
		<div class="">
			<h2>Get a demo account</h2>
			<div class="field" :class="validators.email.control.groupClass">
				<div class="control has-icons-right">
					<div :class="validators.email.control.controlClass">
						<input maxlength="60" type="email" class="input form-control" name="email" id="email" placeholder="Your Email Address" v-model="demo.email" >
						<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.email.control.iconClass"></i></span>
					</div>
				</div>
				<small class="help-block">{{ validators.email.control.errorMessage }}</small>
			</div>
			<div class="field" :class="validators.name.control.groupClass">
				<div class="control has-icons-right">
					<div :class="validators.name.control.controlClass">
						<input maxlength="100" type="textarea" name="name" class="input form-control" id="name" placeholder="Name" v-model="demo.name">
						<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.name.control.iconClass"></i></span>
					</div>
				</div>
				<small class="help-block">{{ validators.name.control.errorMessage }}</small>
			</div>
			<div class="field" :class="validators.contact.control.groupClass">
				<div class="control has-icons-right">
					<div :class="validators.contact.control.controlClass">
						<input maxlength="11" type="textarea" name="contact-number" class="form-control input" id="contact-number" placeholder="Contact Number" v-model="demo.contact">
						<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.contact.control.iconClass"></i></span>
					</div>
				</div>
				<small class="help-block">{{ validators.contact.control.errorMessage }}</small>
			</div>
			<div class="field" :class="validators.company.control.groupClass">
				<div class="control has-icons-right">
					<div :class="validators.company.control.controlClass">
						<input maxlength="100" type="textarea" name="company" class="form-control input" id="company" placeholder="Company Name (Optional)" v-model="demo.company">
						<span class="icon is-right is-small"><i class="form-control-feedback fa" :class="validators.company.control.iconClass"></i></span>
					</div>
				</div>
				<small class="help-block">{{ validators.company.control.errorMessage }}</small>
			</div>
				<!-- <input type="submit"  class="col-sm-6 btn btn-p0 btn-lg pull-right" value="SUBMIT"> -->
				<button class="btn btn-p0 btn-lg column is-6-tablet is-pulled-right" :onclass="{ 'disabled=disabled': disabledBtn }">SUBMIT</button>
		</div>
	</form>
</template>

<script>
	export default {
		props: ['actionurl', 'csrf'],
		data() {
			return {
				demo: {
					email: '',
					name: '',
					contact: '',
					company: ''
				},
				disabledBtn: '',
				validators: {
					email: {
						required: 'This email field cannot be empty',
						stringLength : {
							max: 60,
							message: 'This field cannot exceed 60 character limit'
						},
						regex: {
							rule: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							message: 'Email only accepts alphanumeric characters, follow with @ - . for email'
						},
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default',
							iconClass: ''
						},
						isValid: false
					},
					name: {
						required: 'This name field cannot be empty',
						stringLength : {
							max: 100,
							message: 'This field cannot exceed 100 character limit'
						},
						stringLengthMin: {
							min: 1,
							message: 'This name field cannot be empty'
						},
						regex: {
							rule: /^[a-zA-Z `@()'/,.-]*$/,
							message: 'Name only accepts alphabets characters'
						},
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default',
							iconClass: ''
						},
						isValid: false
					},
					contact: {
						regex: {
							rule: /^\d{9,11}$/,
							message: 'Contact Number only accepts numbers(0-9) and must be between 9-11 digits'
						},
						control: {
							errorMessage: '',
							groupClass: 'has-default',
							controlClass: 'form-control-default',
							iconClass: ''
						},
						isValid: false
					},
                    company: {
                        stringLengthMin: {
                            min: 2,
                            message: 'Company Name must be between 2-100 characters'
                        },
                        regex: {
                            rule: /^([-a-zA-Z0-9%!'*@,&.():_ ™©®`\/-])*$/i,
                            message: 'Company Name only accepts both uppercase(A-Z) and lowercase(a-z) letters, numbers(0-9) and the following symbols % \' ` ( ) - & . / @ * , ! © ® ™'
                        },
                        control: {
                            errorMessage: '',
                            groupClass: 'has-default',
                            controlClass: 'form-control-default',
                            iconClass: ''
                        },
                        isValid: true
                    },
				}
			}
		},
		mounted() {
			let param = window.location.search.slice(1);
			this.demo.package = param.substring(8, param.length)
		},
		watch: {
			'demo.email': function(v) {
				{
					this.validators.email.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}
					this.validators.email.isValid = true
					this.disabledBtn = false;
				}
					// required
					if(v === '') {
						this.validators.email.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.email.required
						}
						this.validators.email.isValid = false;
						this.disabledBtn = true;
					} 
				// stringLength
				if(v.length > this.validators.email.stringLength.max || v.length === 0) {
					this.validators.email.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.email.stringLength.email
					}
					this.validators.email.isValid = false;
					this.disabledBtn = true;
				} 
				// Regex
				let regex = RegExp(this.validators.email.regex.rule)
				if (!regex.test(v)) {
					this.validators.email.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.email.regex.message
					}

					this.validators.email.isValid = false;
					this.disabledBtn = true;
				} else {
					this.validators.email.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}
					this.validators.email.isValid = true
					this.disabledBtn = false;
				}
			},
			'demo.name': function(v) {
				
				{
					this.validators.name.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}

					this.validators.name.isValid = true;
					this.disabledBtn = false;
				}

					// required
					if(this.demo.name === '') {
						this.validators.name.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.name.required
						}
						this.validators.name.isValid = false;
						this.disabledBtn = true;

					} 
				// stringLength
				if(this.demo.name.length > this.validators.name.stringLength.max || this.demo.name.length === 0) {
					this.validators.name.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.name.stringLength.message
					}
					this.validators.name.isValid = false;
					this.disabledBtn = true;

				} 
				// Regex
				if (!this.validators.name.regex.rule.test(this.demo.name)) {
					this.validators.name.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.name.regex.message
					}

					this.validators.name.isValid = false;
					this.disabledBtn = true;
				}

					// stringLengthMin
					if(this.demo.name.length < this.validators.name.stringLengthMin.min || this.demo.name.trim().length === 0) {
						this.validators.name.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.name.stringLengthMin.message
						}
						this.validators.name.isValid = false;
						this.disabledBtn = true;
					}

				},
				'demo.contact': function(v) {
				// Regex
				if (this.validators.contact.regex.rule.test(this.demo.contact)) {
					this.validators.contact.control = {
						groupClass: 'has-success',
						controlClass: 'form-control-success',
						iconClass: 'fa-check',
						errorMessage: ''
					}

					this.validators.contact.isValid = true;
					this.disabledBtn = true;
				} else {
					this.validators.contact.control = {
						groupClass: 'has-danger',
						controlClass: 'form-control-danger',
						iconClass: 'fa-times',
						errorMessage: this.validators.contact.regex.message
					}

					this.validators.contact.isValid = false;
				}

			},
            'demo.company': function(v) {
                {
                    this.validators.company.control = {
                        groupClass: 'has-success',
                        controlClass: 'form-control-success',
                        iconClass: 'fa-check',
                        errorMessage: ''
                    }

                    this.validators.company.isValid = true;
                    this.disabledBtn = false;
                }


                // stringLengthMin
                if(this.demo.company.length < this.validators.company.stringLengthMin.min && this.demo.company.trim().length !== 0 ) {
                    this.validators.company.control = {
                        groupClass: 'has-danger',
                        controlClass: 'form-control-danger',
                        iconClass: 'fa-times',
                        errorMessage: this.validators.company.stringLengthMin.message
                    }
                    this.validators.company.isValid = false;
                    return;
                }

                // Regex
				if (this.demo.company == '') {
                    this.validators.company.isValid = true;

                    this.validators.company.control = {
                        groupClass: '',
                        controlClass: '',
                        iconClass: '',
                    }
				} else if (this.demo.company != '' && this.validators.company.regex.rule.test(this.demo.company)) {
                    this.validators.company.control = {
                        groupClass: 'has-success',
                        controlClass: 'form-control-success',
                        iconClass: 'fa-check',
                        errorMessage: ''
                    }

                    this.validators.company.isValid = true;
				} else {
                    this.validators.company.control = {
                        groupClass: 'has-danger',
                        controlClass: 'form-control-danger',
                        iconClass: 'fa-times',
                        errorMessage: this.validators.company.regex.message
                    }

                    this.validators.company.isValid = false;
				}
            },
		},
		methods: {
			submitForm(e) {
				if(this.demo.email !== '' && this.validators.email.isValid &&
					this.demo.name !== '' && this.validators.name.isValid &&
					this.demo.contact !== '' && this.validators.contact.isValid &&
                    this.validators.company.isValid) {
					console.log('passed')
				this.disabledBtn = false

				document.getElementById('get-a-demo').submit();

                    // Google Analytic submit tracking code

                    var dataObject = {
                        'event': 'trackEvent',
                        'eventCategory': 'Demo',
                        'eventAction': 'Submit',
                        'eventLabel': 'Request Demo Account'
                    };

                    if(typeof dataLayer != 'undefined'){
                        console.log(dataLayer);
                        dataLayer.push(dataObject);
                    }
					// return true
				} else {

					if (this.demo.email == '') {
						this.validators.email.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.email.required
						}
					}

					if (this.demo.name == '') {
						this.validators.name.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.name.required
						}
					}

					if (!this.validators.contact.regex.rule.test(this.demo.contact)) {
						this.validators.contact.control = {
							groupClass: 'has-danger',
							controlClass: 'form-control-danger',
							iconClass: 'fa-times',
							errorMessage: this.validators.contact.regex.message
						}
					}

                    if (this.demo.company != '' && !this.validators.company.regex.rule.test(this.demo.company)) {
                        this.validators.company.control = {
                            groupClass: 'has-danger',
                            controlClass: 'form-control-danger',
                            iconClass: 'fa-times',
                            errorMessage: this.validators.company.regex.message
                        }
                    }

					e.preventDefault();
					console.log('not valid')
						// return false;
						this.disabledBtn = true
					}
				}
			}
		}
	</script>